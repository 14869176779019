import { configureStore } from '@reduxjs/toolkit';
import throttle from 'lodash/throttle';
import customizationReducer from './customizationReducer';
import appReducer from './appReducer';

const store = configureStore({
  reducer: {
    customization: customizationReducer,
    application: appReducer,
  },
});

function saveToLocalStorage(state) {
  try {
    const userDetails = state.application.user;
    const renewalNotifications = state.application.renewalNotifications;
    const paymentNotifications = state.application.paymentNotifications;
    const otherNotifications = state.application.otherNotifications;
    const customers = state.application.customers;
    const suspendedProperties = state.application.suspendedProperties;
    
    window.localStorage.setItem('reminder_app_user_session', JSON.stringify(userDetails));
    window.localStorage.setItem('reminder_app_renewal_notifications', JSON.stringify(renewalNotifications));
    window.localStorage.setItem('reminder_app_payment_notifications', JSON.stringify(paymentNotifications));
    window.localStorage.setItem('reminder_app_other_notifications', JSON.stringify(otherNotifications));
    window.localStorage.setItem('reminder_app_customers', JSON.stringify(customers));
    window.localStorage.setItem('reminder_app_suspended_properties', suspendedProperties);
  } catch (e) {
    console.error('Error saving to localStorage:', e);
  }
}

// Throttle the save function to once per second to avoid excessive writes
store.subscribe(throttle(() => saveToLocalStorage(store.getState()), 1000));

export default store;
