// action - state management
import * as actionTypes from './actions';

var persistedSession = JSON.parse(window.localStorage.getItem('reminder_app_user_session') ?? '{}');
persistedSession = persistedSession.token ? persistedSession : {};

var persistentRenewalNotifications = JSON.parse(window.localStorage.getItem('reminder_app_renewal_notifications') ?? '[]');
persistentRenewalNotifications = persistentRenewalNotifications.length ? persistentRenewalNotifications : [];

var paymentNotifications = JSON.parse(window.localStorage.getItem('reminder_app_payment_notifications') ?? '[]');
paymentNotifications = paymentNotifications.length ? paymentNotifications : [];

var persistentOtherNotifications = JSON.parse(window.localStorage.getItem('reminder_app_other_notifications') ?? '[]');
persistentOtherNotifications = persistentOtherNotifications.length ? persistentOtherNotifications : [];

var customers = JSON.parse(window.localStorage.getItem('reminder_app_customers') ?? '[]');
customers = customers.length ? customers : [];

var suspendedProperties = window.localStorage.getItem('reminder_app_suspended_properties') ?? 0;

export const initialState = {
  isPageLoading : false,
  user: persistedSession,
  renewalNotifications : persistentRenewalNotifications,
  paymentNotifications : paymentNotifications,
  otherNotifications : persistentOtherNotifications,
  customers : customers,
  suspendedProperties : suspendedProperties,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const appReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.SET_PAGE_LOADING:
      return {
        ...state,
        isPageLoading : action.payload
      };
    case actionTypes.SET_USER_SESSION:
      return {
        ...state,
        user: action.payload
      };
    case actionTypes.UPDATE_USER_SESSION:
      return {
          ...state,
          user: {
            ...state.user,
            ...action.payload
        }
      };
    case actionTypes.UPDATE_RENEWAL_NOTIFICATION:
      return {
        ...state,
        renewalNotifications: action.payload
      };
    case actionTypes.UPDATE_PAYMENT_NOTIFICATION:
      return {
        ...state,
        paymentNotifications: action.payload
      };
    case actionTypes.UPDATE_OTHER_NOTIFICATION:
      return {
        ...state,
        otherNotifications: action.payload
      };
    case actionTypes.UPDATE_CUSTOMERS:
      return {
        ...state,
        customers: action.payload
      };
    case actionTypes.UPDATE_SUSPENDED_PROPERTIES:
      return {
        ...state,
        suspendedProperties: action.payload
      };
    default:
      return state;
  }
};

export default appReducer;
