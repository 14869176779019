import { lazy } from 'react';

// project imports
// import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// // sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// setting page routing
const Dashboard = Loadable(lazy(() => import('views/pages/Dashboard/Dashboard')));
const SettingsPage = Loadable(lazy(() => import('views/pages/SettingsPage/SettingsPage')));

const CustomerList = Loadable(lazy(() => import('views/pages/Customers/CustomerList/CustomerList')));
const NewCustomer = Loadable(lazy(() => import('views/pages/Customers/NewCustomer/NewCustomer')));
const TeamMembers = Loadable(lazy(() => import('views/pages/TeamMembers/TeamMembers')));
const Roles = Loadable(lazy(() => import('views/pages/Roles/Roles')));
const Properties = Loadable(lazy(() => import('views/pages/Properties/Properties')));
const Notifications = Loadable(lazy(() => import('views/pages/Notifications/Notifications')));
const Upcoming = Loadable(lazy(() => import('views/pages/Upcoming/Upcoming')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Dashboard />
    },
    {
      path: 'CustomerList',
      element: <CustomerList />
    },
    {
      path: 'NewCustomer',
      element: <NewCustomer />
    },
    {
      path: 'EditCustomer/:customerId',
      element: <NewCustomer />
    },
    {
      path: 'TeamMembers',
      element: <TeamMembers />
    },
    {
      path: 'Roles',
      element: <Roles />
    },
    {
      path: 'Notifications',
      element: <Notifications />
    },
    {
      path: 'Settings',
      element: <SettingsPage />
    },
    {
      path: 'Properties',
      element: <Properties />
    },
    {
      path: 'Upcoming',
      element: <Upcoming />
    },
    {
      path: 'Dashboard',
      element: <DashboardDefault />
    },

    // {
    //   path: 'dashboard',
    //   children: [
    //     {
    //       path: 'default',
    //       element: <DashboardDefault />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-typography',
    //       element: <UtilsTypography />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-color',
    //       element: <UtilsColor />
    //     }
    //   ]
    // },
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-shadow',
    //       element: <UtilsShadow />
    //     }
    //   ]
    // },
    // {
    //   path: 'icons',
    //   children: [
    //     {
    //       path: 'tabler-icons',
    //       element: <UtilsTablerIcons />
    //     }
    //   ]
    // },
    // {
    //   path: 'icons',
    //   children: [
    //     {
    //       path: 'material-icons',
    //       element: <UtilsMaterialIcons />
    //     }
    //   ]
    // },
    // {
    //   path: 'sample-page',
    //   element: <SamplePage />
    // }
  ]
};

export default MainRoutes;
