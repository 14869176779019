import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress, CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useTheme } from '@emotion/react';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const application = useSelector((state) => state.application);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
          <Backdrop
            // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            sx={{ color: '#fff', zIndex: 4000}}
            open={application.isPageLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
